// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-directory-js": () => import("./../../../src/pages/directory.js" /* webpackChunkName: "component---src-pages-directory-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-the-legacy-senior-communities-js": () => import("./../../../src/pages/the-legacy-senior-communities.js" /* webpackChunkName: "component---src-pages-the-legacy-senior-communities-js" */),
  "component---src-templates-sponsor-booth-js": () => import("./../../../src/templates/sponsorBooth.js" /* webpackChunkName: "component---src-templates-sponsor-booth-js" */),
  "component---src-templates-sponsor-gold-js": () => import("./../../../src/templates/sponsorGold.js" /* webpackChunkName: "component---src-templates-sponsor-gold-js" */),
  "component---src-templates-sponsor-premier-js": () => import("./../../../src/templates/sponsorPremier.js" /* webpackChunkName: "component---src-templates-sponsor-premier-js" */),
  "component---src-templates-sponsor-title-js": () => import("./../../../src/templates/sponsorTitle.js" /* webpackChunkName: "component---src-templates-sponsor-title-js" */)
}

